<template>
  <div class="doctorBox">
    <div class="return" @click="goBack">
      <img src="../../assets/img/returnImg.png">返回
    </div>
    <div class="doctorBj">
      <img :src="doctorDetail1.headImg">
      <h4>{{ doctorDetail1.name }}</h4>
      <h6>{{doctorDetail1.departmentStr}}<span>|</span>{{doctorDetail1.title}}</h6>
    </div>
    <div class="doctorTime">
      <h5>
        <img src="../../assets/img/doctorTitle.png" >
        <b>门诊信息</b>
        <!-- <span>（诊费合计：200）</span> -->
      </h5>
      <div class="doctorTimeTable">
        <table cellpadding="0" cellspacing="0">
          <tr>
            <th>出诊时间</th>
            <th>上午</th>
            <th>下午</th>
            <th>晚上</th>
          </tr>
          <tr>
            <td>
              <div v-for="item in weekList" :key="item.id">
                <!-- <span>{{item.orders[0].date.slice(5,10)}} {{item.orders[0].week}}</span> -->
                <span>{{item.orders[0].date.slice(5,10)}} {{item.orders[0].week}}</span>
              </div>
            </td>
            <td>
              <div v-for="item in weekList" :key="item.id">
                <span v-for="item1 in item.orders" :key="item1.id" @click="chooseWeek(item1)">
                  <b v-if="item1.vartPart == '上午'" >
                    <u v-if="item1.isHave == '无号'"></u>
                    <u v-else>{{item1.isHave}}</u>
                  </b>
                </span>
              </div>
            </td>
            <td>
              <div v-for="item in weekList" :key="item.id">
                <span v-for="item1 in item.orders" :key="item1.id" @click="chooseWeek(item1)">
                  <b v-if="item1.vartPart == '下午'" >
                    <u v-if="item1.isHave == '无号'"></u>
                    <u v-else>{{item1.isHave}}</u>
                  </b>
                </span>
              </div>
            </td>
            <td>
              <div v-for="item in weekList" :key="item.id">
                <span v-for="item1 in item.orders" :key="item1.id" @click="chooseWeek(item1)">
                  <b v-if="item1.vartPart == '晚上'" >
                    <u v-if="item1.isHave == '无号'"></u>
                    <u v-else>{{item1.isHave}}</u>
                  </b>
                </span>
              </div>
            </td>
          </tr>
        </table>
      </div>
      
    </div>
    <div class="doctorTime doctorDetail">
      <h5>
        <img src="../../assets/img/doctorTitle.png" >
        <b>专家介绍</b>
      </h5>
      <p>{{doctorDetail1.introductionTitle}}</p>
    </div>
    <div class="doctorTime doctorDetail" style="padding-bottom:100px">
      <h5>
        <img src="../../assets/img/doctorTitle.png" >
        <b>擅长领域</b>
      </h5>
      <p>{{doctorDetail1.goodDesc}}</p>
    </div>
  </div>
</template>
 
<script>
import { useRoute, useRouter } from 'vue-router'
import { defineComponent,reactive, onMounted, getCurrentInstance ,ref} from "vue";
import { ElMessage } from 'element-plus'
export default defineComponent({
  setup() {
    const currentInstance = getCurrentInstance();
    const { $axios } = currentInstance.appContext.config.globalProperties;
    const Router = useRouter() //跳转
    const Route = useRoute() //获取到值
    let newDoctorId =Route.query.doctorId;
    let newHospitalId= '1452811085769940994';
    const doctorDetail = ref({});
    const weekList = ref();
    onMounted(()=>{
      //getCode();
      //$cookies.set("openId",'odwbRt14B9ztzEhJUFpAfXJ1juAA');
      $axios.get('api/v1/doctor/share',{params: {doctorId:newDoctorId}}).then(res => {
        if(res.code == 1){
          const data = res.data
          doctorDetail.value = data;
          // if(data.hospitalList != null || data.hospitalList != ''|| data.hospitalList != []){
          //   newHospitalId = data.hospitalList[0].hospitalId
          // }
        }else{
            console.log('获取医生出诊信息')
        }
      })
      $axios.get('api/v1/doctor/buyServiceNew',{params: {doctorId:newDoctorId,registerHospitalId:newHospitalId}}).then(res => {
        if(res.code == 1){
          var list = res.data.visitTimeList
          weekList.value = list
         // weekList.value = list.slice(0,6)
          console.log('获取医生出诊信息',res)
        }else{
          console.log('获取医生出诊信息')
        }
      })
    });
    //获取openid
    const getCode = async () => {
      var local = window.location.href // 获取页面url
      var code = getUrlCode().code // 截取code
      if (code== null || code == '') { // 如果没有code，则去请求
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4f53ddd052e1798c&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
      } else {
        $axios.get("api/v1/register/getOpenId?code="+code).then(res =>{
          var result1 = res.result
          $cookies.set("openId",result1.openId); 
        })
      }
    };
    const getUrlCode = ()=> { // 截取url中的code方法
        var url = location.search
        var theRequest = new Object()
        if (url.indexOf("?") != -1) {
            var str = url.substr(1)
            var strs = str.split("&")
            for(var i = 0; i < strs.length; i ++) {
                theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
            }
        }
        return theRequest
    };
    const goBack = () => {
      Router.push({
          path:'/Appointment'
      })
    };
    const chooseWeek = (item) => {
      if(item.isHave == '无号'){
        ElMessage({
          message: '无号，换个时间吧！',
          type: 'warning'
        })
      }else{
        Router.push({
          path:'/VisitAppointment',
          query: {
            doctorId: newDoctorId,
            visitDate:item.date,
            visitShangwu:item.vartPart,
            registerHospitalId:'1452811085769940994'
          }
        })
      }
    };
    return {
      goBack,
      doctorDetail1:doctorDetail,
      weekList,
      chooseWeek
    }
  }
});
</script>
<style scoped>
  .doctorBox{width:100%;height:100%}
  .return{width:96%;height:40px;padding:0 3%;text-align:left;font-size:16px;line-height:40px;letter-spacing:1px;color: #999}
  .return img{width:22px;height:22px;float:left;margin:8px 5px 0 0}
  .doctorBj{width:100%;height:200px;background:url(../../assets/img/doctorBg.png);color:#fff;font-size: 18px;line-height: 22px;text-align: center;letter-spacing: 2px}
  .doctorBj img{width:56px;height:64px;border-radius:50%;overflow:hidden;margin-top:30px}
  .doctorBj h4{width:100%;height:22px;margin:10px 0 15px;font-weight: 400}
  .doctorBj h6{font-weight: 400;font-size: 16px}
  .doctorBj h6 span{margin:0 10px;color:#999}
  .doctorTimeTable{width:100%;height:165px;overflow-y:scroll;overflow-x:hidden;}
  .doctorTimeXinxi .left{width:25%;float: left;}


  .doctorTime{width:94%;margin:20px 3%;text-align:left;}
  .doctorTime h5{width:100%;height:30px;font-weight: 500;font-size:16px;line-height:30px;letter-spacing:1px;color:#333;}
  .doctorTime h5 img{width:14px;height:14px;margin-right:5px;}
  .doctorTime h5 span{font-size:12px;color:#752526;}
  .doctorTime table{width:100%;border: 1px solid rgba(180, 180, 180, 0.2);overflow: hidden;}
  .doctorTime table th,.doctorTime table td{width:12.5%;height:40px;font-weight:500;font-size:12px;line-height:16px;color:#FFF;
  text-align: center;border-right: 1px solid rgba(180, 180, 180, 0.2);border-bottom: 1px solid rgba(180, 180, 180, 0.2);}

  .doctorTime table tr td div{height:40px;font-weight:500;font-size:16px;line-height:40px;border-bottom: 1px solid rgba(180, 180, 180, 0.2);}
  .doctorTime table td span{width:100%;display:block;font-weight:500;font-size:12px;line-height:40px;text-align: center;}
  .doctorTime table th span{display:none;}
  .doctorTime table th span:first-child{display:block;}
  .doctorTime table td b{font-weight:500;height:40px;line-height:40px}
  .doctorTime table th{color:#FFF;background: #752526}
  .doctorTime table td{color:#752526;height:40px;line-height:40px}
  .doctorTime table td:last-child{border-right:none;}
  .doctorTime table td b u{text-decoration: none;}
  .doctorTime table tr:last-child td{border-bottom:none;}
  .doctorTime p{width:100%;font-weight:500;font-size: 14px;line-height: 22px;letter-spacing:1px;color: #752526;}
  .doctorDetail{margin:0 3%;}
  .doctorDetail p{width:100%;font-weight: 400;font-size: 12px;line-height:20px;letter-spacing: 1px;color:#666;margin:0;}
  .doctorDetail + .doctorDetail{margin-top: 20px;}
</style>