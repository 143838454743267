<template>
  <div class="doctorBox">
    <div class="return" @click="goBack">
      <img src="../../assets/img/returnImg.png">返回
    </div>
    <el-form ref="form" :model="form" label-width="80px" style="border-top:1px solid rgba(0, 0, 0, 0.2);padding-top:15px">
      <el-form-item label="医生姓名">
        <input disabled=false v-model="orderDetail1.doctorName" />
      </el-form-item>
      <el-form-item label="就诊科室">
        <input disabled=false v-model="orderDetail1.departmentName"/>
      </el-form-item>
      <el-form-item label="就诊人">
        <input disabled=false v-model="orderDetail1.patientName" />
      </el-form-item>
      <el-form-item label="就诊号种" v-if="orderDetail1.title != null || orderDetail1.title != '' " >
       <input disabled=false v-model="orderDetail1.title" />
      </el-form-item>
      <el-form-item label="就诊号种" v-if="orderDetail1.title == null || orderDetail1.title == '' " >
       <input disabled=false value="医生" />
      </el-form-item>
      <el-form-item label="就诊日期" style="width:100%">
        <input disabled=false  v-model="orderDetail1.visitTime"  style="width:100%"/>
      </el-form-item>
      <!-- <el-form-item label="就诊时间">
        <el-input disabled=false  v-model="orderDetail"></el-input>
      </el-form-item> -->
    </el-form>
    <button class="createBtn" v-if="orderDetail1.status != 4  && orderDetail1.status != 10" @click="onSubmit">取消挂号</button>
  </div>
</template>
 
<script>
import { useRoute, useRouter } from 'vue-router'
import { defineComponent,reactive, onMounted, getCurrentInstance ,ref} from "vue";
import { ElMessage } from 'element-plus'
export default defineComponent({
  setup() {
    const currentInstance = getCurrentInstance();
    const { $axios } = currentInstance.appContext.config.globalProperties;
    const Router = useRouter() //跳转
    const Route = useRoute() //获取到值
    let orderId = Route.query.id;
    let orderPayId = Route.query.orderPayId;

    const orderDetail = ref({});
    onMounted(()=>{
       $axios.get('api/v1/register/recordDetail',{params: {recordId:orderId,orderPayId:orderPayId}}).then(res => {
        if(res.code == 200){
          const data = res.result
          orderDetail.value = res.result;
        }else{
            console.log('获取订单详细信息')
        }
      })
    });
    const goBack = () => {
      Router.push({path:'/BookingManagement'})
    };
    const onSubmit = () => {
      $axios.post('api/v1/order/refound',{doctorId:orderDetail.value.doctorId,registerRecordId:orderDetail.value.id,openid:$cookies.get("openId")}).then(res => {
        if(res.code == 200) {
          ElMessage({
            message: res.message,
            type: 'success',
          })
          Router.push({path:'/BookingManagement'})
        }else {
          ElMessage({
            message: res.msg,
            type: 'warning',
          })
        }
      })
    };
    return {
      goBack,
      orderDetail1:orderDetail,
      onSubmit
    }
  }
});
</script>
<style>
  .doctorBox{width:100%;height:100%}
  .return{width:96%;height:40px;padding:0 3%;text-align:left;font-size:16px;line-height:40px;letter-spacing:1px;color: #999}
  .return img{width:22px;height:22px;float:left;margin:8px 5px 0 0}
  .doctorBox .el-form{
    width: 94%;margin:0 3%;}
  .doctorBox .el-form .el-form-item label{font-weight:1000;font-size:16px;color:#333;height:40px;line-height:40px}
  .doctorBox .el-select,.doctorBox .el-input{width:90%;}
  .doctorBox .el-form .el-input__wrapper{ box-shadow:none;font-size:16px;color:#666}
  .doctorBox .el-form .el-form-item{border-bottom:1px solid rgba(180, 180, 180, 0.2);margin-bottom:15px}
  .doctorBox .el-form .el-input.is-disabled .el-input__wrapper{background:none;border:none;box-shadow:none}
  h5.visitTishi{font-weight: 400;font-size: 16px;line-height:24px;color: #752526;text-align:left;width:94%;margin:0 3%}
  p.visitTishi{font-weight: 500;font-size: 12px;line-height:20px;color: #752526; text-align:left;width:94%;margin:0 3%}
  .doctorBox .el-form-item input{border:none;background:none;color:#000 !important;font-size:14px;}
  .createBtn{
    width: 90%;
    height: 36px;
    border:none;
    background: #752526;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height:36px;
    text-align: center;
    color: #FFFFFF;
    position: absolute;
    left:5%;
    bottom:40px;
  }
</style>