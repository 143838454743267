<template>
  <div class="doctorBox" v-bind:style="{height:boxHeight}" >
    <div class="return" @click="goBack">
      <img src="../../assets/img/returnImg.png">返回
    </div>
    <!-- <div class="personalBj">
      <img :src="wxInfo1.headimgurl">
      <h4>{{wxInfo1.nickname}}</h4>
    </div> -->
    <div class="personalList">
      <div class="personalItem" v-for="item in personList1" :key='item.id'>
        <div @click="goDetail(item.id)">
          <h2>{{item.name}}</h2>
          <h4>{{item.idCard}}</h4>
          <h6>{{item.mobile}}</h6>
        </div>
        <h1 @click="deletePerson(item.id)">  删除</h1>
      </div>
      <br/>
      <!-- <a>
        <div>
          <h2>张三丰 <span>| 男生</span></h2>
          <h4>130676519981203396</h4>
          <h6>1994-05-03<span>|</span>15176883384</h6>
        </div>
        <h1>  删除</h1>
      </a> -->
    </div>
    <button class="createBtn" @click="goAdd">新增就诊人</button>
  </div>
</template>
 
<script>
import { useRouter } from 'vue-router'
import { defineComponent, onMounted, getCurrentInstance,ref} from "vue";
import { ElMessage } from 'element-plus'
export default defineComponent({
  setup() {
    const currentInstance = getCurrentInstance();
    const { $axios } = currentInstance.appContext.config.globalProperties;
    const Router = useRouter() //跳转
    const personList = ref({});
    const wxInfo = ref({});
    onMounted(()=>{
      // $axios.get('api/v1/patient/getWxInfo',{params: {openid:$cookies.get("openId")}}).then(res => {
      //   if (res.code == 1) {
      //     wxInfo.value = res.data
      //   } else {
      //     console.log('res.msg')
      //   }
      // })
      $axios.get('api/v1/registerPatient/list',{params: {openid:$cookies.get("openId")}}).then(res => {
        if(res.code == 200){
          personList.value = res.result;
        }else{
            console.log('获取就诊人列表')
        }
      })
    });
    const goAdd = () => {
      Router.push({
        path:'/CreateProfile'
      })
    };
    const goBack = () => {
      Router.push({
          path:'/Personal'
      })
    };
    const goDetail = (id) => {
      Router.push({
        path:'/EditProfile',
        query: {
          id:id
        }
      })
    };
    const deletePerson = (id) =>{
      $axios.get('api/v1/registerPatient/delete',{params: {patientId:id,openid:$cookies.get("openId")}}).then(res => {
        if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            })
            $axios.get('api/v1/registerPatient/list',{params: {openid:$cookies.get("openId")}}).then(res => {
              if(res.code == 200){
                personList.value = res.result;
              }else{
                  console.log('获取就诊人列表')
              }
            })
          }else {
            console.log(res.message)
          }
      })

    }
    return {
      personList1:personList,
      // wxInfo1:wxInfo,
      goAdd,
      goDetail,
      goBack,
      deletePerson
    }
  }
});
</script>
<style scoped>
  .doctorBox{width:100%;background:#f1f1f1;}
  .createBtn{position: fixed;left:5%;bottom:20px;}
  .return{width:96%;height:40px;padding:0 3%;text-align:left;font-size:16px;line-height:40px;letter-spacing:1px;color: #999;background:#fff}
  .return img{width:22px;height:22px;float:left;margin:8px 5px 0 0}
  .personalBj{width:100%;height:150px;background:url(../../assets/img/personBg.png);color:#fff;font-size:18px}
  .personalBj img{width:56px;height:56px;border-radius:50%;overflow:hidden;margin:50px 15px 0 20px;float:left;}
  .personalBj h4{width:calc(100% - 106px);height:22px;line-height:150px;font-weight: 400;float:left;text-align:left;}

  .personalList{width:94%;display: block;margin:20px 3% 70px;border-radius:6px;overflow: hidden}
  /* .personalList{width:94%;display: block;margin:20px 3% 70px;border-radius:6px;background:#752526;overflow: hidden} */
  .personalList .personalItem{width:100%;height:90px;display:block;text-align:left;padding:0 0;overflow: hidden;padding-bottom:20px;border-bottom:1px solid #EDEDED;position: relative;}
  .personalList .personalItem div{width:calc(100% - 40px);height:90px;border-radius:6px;background:#fff;overflow: hidden;}
  .personalList .personalItem div h2{width:94%;height:30px;margin:10px 3% 0;text-align: left;font-weight: 500;font-size: 16px;line-height:30px;letter-spacing:0.5px;color: #333333;}
  .personalList .personalItem div h2 span{font-size: 12px;line-height: 22px;letter-spacing:0.5px;color: #666666;}
  .personalList .personalItem div h4,.personalList h6{width:94%;height:24px;margin:0 3%;text-align: left;font-weight: 400;font-size: 12px;line-height:24px;letter-spacing: -0.408px;color:#999}
  .personalList .personalItem div h6 span{margin:0 10px;}
  .personalList h1{width:50px;height:90px;line-height:90px;position:absolute;right:0;top:0;background:#fff;border-radius:6px;font-weight:400;font-size:14px;text-align: center;color: #752526;}
</style>