// import VueRouter from "vue-router"
import { createRouter, createWebHistory } from 'vue-router'
 
const routerHistory = createWebHistory()

import Index from '../view/Index'
import CreateProfile from '../view/personal/createProfile'

import Appointment from '../view/appointment/appointment'
import Doctor from '../view/appointment/doctor'
import VisitAppointment from '../view/appointment/visitAppointment'
import AppointmentConfirmation from '../view/appointment/appointmentConfirmation'
import PaymentResults from '../view/appointment/paymentResults'
import BookingManagement from '../view/bookingManagement/bookingManagement'
import BookingDetails from '../view/bookingManagement/bookingDetails'

import Personal from '../view/personal/personal'
import PersonalManagement from '../view/personal/personalManagement'
import EditProfile from '../view/personal/editProfile'

import newsIndex from '../view/news/newsIndex'
import newsDetail from '../view/news/newsDetail'
import kefu from '../view/news/kefu'

import dcNewsIndex from '../view/dcNews/dcNewsIndex'
import dcNewsDetail from '../view/dcNews/dcNewsDetail'

const routes = [
  //配置路由的路径
  {
    path:'/',
    component:Index
  },
  {
      path:'/Index',
      component:Index,
      meta:{
        title:'首页'
      }
  },
  {
    path:'/CreateProfile',
    component:CreateProfile,
    meta:{
      title:'患者建档'
    }
  },
  {
    path:'/Appointment',
    component:Appointment,
    meta:{
      title:'预约挂号'
    }
  },
  {
    path:'/Doctor',
    component:Doctor,
    meta:{
      title:'医生信息'
    }
  },
  {
    path:'/VisitAppointment',
    component:VisitAppointment,
    meta:{
      title:'就诊预约'
    }
  },
  {
    path:'/AppointmentConfirmation',
    component:AppointmentConfirmation,
    meta:{
      title:'预约信息确认'
    }
  },
  {
    path:'/PaymentResults',
    component:PaymentResults,
    meta:{
      title:'支付结果'
    }
  },
  {
    path:'/BookingManagement',
    component:BookingManagement,
    meta:{
      title:'预约管理'
    }
  },
  {
    path:'/BookingDetails',
    component:BookingDetails,
    meta:{
      title:'订单详情'
    }
  },
  {
    path:'/Personal',
    component:Personal,
    meta:{
      title:'个人中心'
    }
  },
  {
    path:'/PersonalManagement',
    component:PersonalManagement,
    meta:{
      title:'档案管理'
    }
  },
  {
    path:'/EditProfile',
    component:EditProfile,
    meta:{
      title:'档案编辑'
    }
  },
  {
    path:'/newsIndex',
    component:newsIndex,
    meta:{
      title:'五大健康'
    }
  },
  {
    path:'/newsDetail',
    component:newsDetail,
    meta:{
      title:'五大健康'
    }
  },
  {
    path:'/kefu',
    component:kefu,
    meta:{
      title:'企业客服'
    }
  },
  {
    path:'/dcNewsIndex',
    component:dcNewsIndex,
    meta:{
      title:'百年赵炳南医馆'
    }
  },
  {
    path:'/dcNewsDetail',
    component:dcNewsDetail,
    meta:{
      title:'百年赵炳南医馆'
    }
  }

]

const router=new createRouter({
  history:routerHistory,
  routes
})
router.beforeEach(async (to) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
  }
});
export default router;