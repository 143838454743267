<template>
  <div class="doctorBox" style="overflow-x:hidden;">
    <div class="return" @click="goBack">
      <img src="../../assets/img/returnImg.png">返回
    </div>
    <el-form ref="form" :model="form" label-width="80px" style="border-top:1px solid rgba(0, 0, 0, 0.2);padding-top:15px;overflow-x:hidden;">
      <el-form-item label="就诊人">
        <el-select placeholder="请选择就诊人" v-model="person" @change="selectPerson($event)">
          <el-option v-for="item in personList" :key="item.id" :label="item.name" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="就诊时间">
        <el-select placeholder="请选择就诊时间" v-model="jiuzhenTime" @change="selectTime($event)">
          <el-option v-for="item in timeList" :key="item" :label="item.startTime+'-'+item.endTime" :value="item.startTime+'-'+item.endTime"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="就诊时间" class="tishi shuju">
        <p>{{visitDate}} {{visitShangwu}}</p>
      </el-form-item>
      <el-form-item label="剩余号源" class="tishi shuju">
        <p>{{shengyuhaoyuan}}个</p>
      </el-form-item>
      <el-form-item label="提示信息" style="color:#f00" class="tishi">
        <p> 预约时收取全款，到院取号时退还医保支付部分的费用</p>
      </el-form-item>
      
    </el-form>
    <h5 class="visitTishi">【温馨提示】</h5>
    <p class="visitTishi">一、微信服务号可预约范围：<br >
      普通门诊、专病门诊、专家门诊、特需门诊以及膏方门诊。<br >
      普通门诊、专病门诊预约患者，可享受优先诊疗；<br >
      专家门诊、特需门诊就诊顺序，以预约签到排序为准。<br >
      二、严格实行实名制：预约时需填写真实有效的患者信息，包括姓名、身份证、社保卡等有效证件号、手机号等。请务必填写有效手机号码，以便查收预约成功、停诊等医院通知。<br >
      三、预约时间：<br >
      就诊前15天至就诊日前1天(含双休日），23:00点以前。<br >
      服务号开放号源时间为每天6:00点。</p>
      <button class="createBtn" @click="onSubmit">下一步</button>
  </div>
</template>
 
<script>
import { useRoute, useRouter } from 'vue-router'
import { defineComponent,reactive, onMounted, getCurrentInstance ,ref,h} from "vue";
import { ElMessage } from 'element-plus'
export default defineComponent({
  setup() {
    const currentInstance = getCurrentInstance();
    const { appContext } = getCurrentInstance();
    const { $axios } = currentInstance.appContext.config.globalProperties;
    const Router = useRouter() //跳转
    const Route = useRoute() //获取到值
    

    let doctorId = Route.query.doctorId;
    let registerHospitalId= Route.query.registerHospitalId;
    let visitDate= Route.query.visitDate;
    let visitShangwu = Route.query.visitShangwu;

    const person = ref();
    const personId = ref();
    
    const jiuzhenTime = ref();
    const visitTimeId = ref();
    const visitTimeDoctorRegisterId = ref();
    
    const personList = ref();
    const timeList = ref();
    const shengyuhaoyuan = ref();

    onMounted(()=>{
      $axios.get('api/v1/registerPatient/list',{params: {openid:$cookies.get("openId")}}).then(res => {
        if(res.code == 200){
          personList.value = res.result
          if(res.result == '' || res.result == [] || res.result == null){
            Router.push({
                path:'/CreateProfile',
                query: {
                  doctorId: doctorId,
                  registerHospitalId:'1452811085769940994',
                  visitTime:visitDate
                }
            })
          }else{
            personList.value = res.result
          }
        }else if(res.code == 500){
          Router.push({
            path:'/CreateProfile',
            query: {
              doctorId: doctorId,
              registerHospitalId:'1452811085769940994',
              visitTime:visitDate
            }
          })
        }else{
          console.log('就诊人列表')
        }
      })
      $axios.get('api/v1/doctor/getOrderDetail',{params: {doctorId:doctorId,registerHospitalId:'1452811085769940994',visitTime:visitDate}}).then(res => {
        if(res.code == 1){
          timeList.value = res.data
          shengyuhaoyuan.value = res.data.length
          console.log('shengyuhaoyuan',res.data.length,shengyuhaoyuan)
        }else{
          console.log('获取医生出诊信息')
        }
      })
    });
    const selectPerson =(obj) =>{
      personId.value = obj.id
      person.value = obj.name
      
    };
    // const selectTime =(obj) =>{
    //   let str = obj.slice(0,5)
    //   jiuzhenTime.value = obj
    //   console.log('33333333',str)
    //   for(var i=0;i<timeList.value.length;i++){
    //     if(timeList.value[i].startTime == str ){
    //       visitTimeDoctorRegisterId.value = timeList.value[i].doctorRegisterId
    //       visitTimeId.value = timeList.value[i].id
    //       console.log('111111111',timeList.value[i].doctorRegisterId,timeList.value[i].id)
    //     }
    //   }
      
    // };
    const goBack = () => {
      Router.push({
          path:'/Doctor',
          query: {
            doctorId: doctorId
          }
      })
    };
    const onSubmit = () => {
      if(person.value == null){
        ElMessage({
          message: '请选择就诊人',
          type: 'warning',
        })
        return
      }
      // else if(jiuzhenTime.value == null){
      //   ElMessage({
      //     message: '请选择就诊时间',
      //     type: 'warning',
      //   })
      //   return
      // }
      Router.push({
          path:'/AppointmentConfirmation',
          query: {
            doctorId: doctorId,
            person:person.value,
            personId:personId.value,
            jiuzhenTime:jiuzhenTime.value,
            visitTime:visitDate,
            visitShangwu:Route.query.visitShangwu,
            visitTimeId:timeList.value[0].id,
            visitTimeDoctorRegisterId:timeList.value[0].doctorRegisterId,
            registerHospitalId:registerHospitalId
          }
      })
    };
    
    return {
      goBack,
      timeList,
      visitDate,
      visitShangwu,
      shengyuhaoyuan,
      //selectTime,
      jiuzhenTime,
      person,
      personId,
      personList,
      selectPerson,
      visitTimeId,
      visitTimeDoctorRegisterId,
      onSubmit
    }
  }
});
</script>
<style>
body,html{overflow-x: hidden;}
  .doctorBox{width:100%;height:100%;}
  .return{width:96%;height:40px;padding:0 3%;text-align:left;font-size:16px;line-height:40px;letter-spacing:1px;color: #999}
  .return img{width:22px;height:22px;float:left;margin:8px 5px 0 0}
  .doctorBox .el-form{width: 94%;margin:0 3%;}
  .doctorBox .el-select-dropdown__wrap{max-width:240px;min-width: 240px;}
  .doctorBox .el-form .el-form-item label{font-weight:1000;font-size:16px;color:#333;height:40px;line-height:40px}
  .doctorBox .el-select,.doctorBox .el-input{width:90%;}
  .doctorBox .el-form .el-input__wrapper{ box-shadow:none;font-size:16px;color:#666}
  .doctorBox .el-form .el-form-item{border-bottom:1px solid rgba(180, 180, 180, 0.2);margin-bottom:15px}
  h5.visitTishi{font-weight: 400;font-size: 16px;line-height:24px;color: #752526;text-align:left;width:94%;margin:0 3%}
  p.visitTishi{font-weight: 500;font-size: 12px;line-height:20px;color: #752526; text-align:left;width:94%;margin:0 3%}
  .tishi p{font-size:16px;color:#f00 !important;text-align: left;margin: 0;}
  .tishi.shuju p{font-size:16px;color:#333 !important;text-align: left;margin: 0;}
  .tishi .el-form-item__label{font-size:16px;font-weight:1000;color:#333;}
  .createBtn{
    width: 90%;
    height: 36px;
    border:none;
    background: #752526;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height:36px;
    text-align: center;
    color: #FFFFFF;
    position: absolute;
    left:5%;
    bottom:40px;
  }
</style>