<template>
  <div class="wrap-box">
    <div id="header" class="newsHeader">
      <img class="newsLogo" src="../../assets/img/newsLogo.png" >
      <span @click="close"><img :src="menuImg1" ></span>
    </div>
    <ul class="newsMenu" v-show="showMenu">
      <li @click="getNewsList(0)">
        <h4>生育健康<span class="right"><img src="../../assets/img/newsNext.png" ></span></h4>
      </li>
      <li @click="getNewsList(2)">
        <h4>儿童健康<span class="right"><img src="../../assets/img/newsNext.png" ></span></h4>
      </li>
      <li @click="getNewsList(3)">
        <h4>体重健康<span class="right"><img src="../../assets/img/newsNext.png" ></span></h4>
      </li>
      <li @click="getNewsList(4)">
        <h4>睡眠健康<span class="right"><img src="../../assets/img/newsNext.png" ></span></h4>
      </li>
      <li @click="getNewsList(5)">
        <h4>脊柱健康<span class="right"><img src="../../assets/img/newsNext.png" ></span></h4>
      </li>
      <li @click="getNewsList(6)">
        <h4>老年健康管理<span class="right"><img src="../../assets/img/newsNext.png" ></span></h4>
      </li>
      <li @click="getNewsList(7)">
        <h4>疑难病管理<span class="right"><img src="../../assets/img/newsNext.png" ></span></h4>
      </li>
    </ul>
    <div class="newsList" v-show="showList">
      <h2><img src="../../assets/img/newsTitle.png" >&nbsp;{{title}}</h2>
      <ul class="newsUl">
        <li v-for="item in newsList1" :key="item.id" @click="goDetail(item)">
          <img :src="item.thumbnailPic" >
          <h4>{{item.title}}</h4>
          <p>{{item.createTime}}</p>
        </li>
      </ul>
      <ul class="newsPage">
        <li v-for="(item,index) in pagesList1" :key="item.id" @click="goPage(index)">
          {{index +1}}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router'
import { defineComponent,reactive, onMounted, getCurrentInstance ,ref} from "vue";
export default defineComponent({
  setup() {
    const currentInstance = getCurrentInstance();
    const { $axios } = currentInstance.appContext.config.globalProperties;
    const Router = useRouter() //跳转
    const Route = useRoute() //获取到值
    const newsList = ref({});
    const pagesList = ref({});
    const menuImg1 = ref(require('../../assets/img/newsClose.png'));

    const type = ref(0)
    const title = ref('生育健康')
    const urlPageNo =1
    const pageSize = 10
    const showMenu = ref(false)
    const showList= ref(false)

    onMounted(()=>{
      //getCode();
      //$cookies.set("openId",'odwbRt14B9ztzEhJUFpAfXJ1juAA'); 
      let queryType = Route.query.type
      getNewsList(queryType);
    });
    const getNewsList = (queryType) =>{
      if(queryType == 0 || queryType == '' || queryType == undefined){
        queryType = 0
        title.value = '生育健康'
      }else if(queryType == 2){
        title.value = '儿童健康'
      }else if(queryType == 3){
        title.value = '体重健康'
      }else if(queryType == 4){
        title.value = '睡眠健康'
      }else if(queryType == 5){
        title.value = '脊柱健康'
      }else if(queryType == 6){
        title.value = '老年健康管理'
      }else if(queryType == 7){
        title.value = '疑难病管理'
      }
      
      type.value = queryType
      $axios.get('api/v1/articleManagement/list',{params:{type:queryType,pageNo:urlPageNo,pageSize:pageSize}}).then(res => {
        if(res.code == 200){
          showList.value  = true
          showMenu.value = false
          menuImg1.value  = require('../../assets/img/newsMenu.png')
          newsList.value = res.result.records;
          pagesList.value = res.result.pages
        }else{
            console.log('获取新闻列表')
        }
      })
    }
    const getCode = async () => {
      var local = window.location.href // 获取页面url
      var code = getUrlCode().code // 截取code
      if (code== null || code == '') { // 如果没有code，则去请求
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4f53ddd052e1798c&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
      } else {
        $axios.get("api/v1/register/getOpenId?code="+code).then(res =>{
          var result1 = res.result
          $cookies.set("openId",result1.openId); 
        })
      }
    };
    const getUrlCode = ()=> { // 截取url中的code方法
        var url = location.search
        var theRequest = new Object()
        if (url.indexOf("?") != -1) {
            var str = url.substr(1)
            var strs = str.split("&")
            for(var i = 0; i < strs.length; i ++) {
                theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
            }
        }
        return theRequest
    };
    const goPage=(index)=>{
      urlPageNo.value = index +1;
      getNewsList(type)
    };
    const goDetail = (item) => {
      Router.push({
          path:'/newsDetail',
          query: {
            id: item.id,
            type:type.value
          }
      })
    };
    const close = () => {
      if(showMenu.value == false){
        showList.value  = false
        showMenu.value = true
        menuImg1.value  = require('../../assets/img/newsClose.png')
      }else{
        showList.value  = true
        showMenu.value = false
        menuImg1.value  = require('../../assets/img/newsMenu.png')
      }
    };
    return {
      newsList1:newsList,
      pagesList1:pagesList,
      getNewsList,
      goDetail,
      goPage,
      close,
      menuImg1,
      type,
      title,
      urlPageNo,
      pageSize,
      showMenu,
      showList,
    }
  }
});
</script>

<style>
  .wrap-box{height: 100%}
  /* 推荐 */
  .newsHeader{width:94%;height:30px;padding:20px 3% 10px;}
  .newsHeader .newsLogo{width:134px;height:31px;float:left;}
  .newsHeader span{width:60px;height:62px;display:block;float: right;}
  .newsHeader span img{width:24px;height:24px;margin-top:5px;float: right;}
  .newsMenu{width:94%;height:500px;padding:0 3% 0;clear: both;}
  .newsMenu li{width:100%;height:40px;line-height:40px;border-bottom: 1px solid #EAEAEA;}
  .newsMenu li h4{width:100%;height:40px;line-height:40px;font-weight: 400;font-size:14px;letter-spacing:1px;color: #333;text-align: left;}
  .newsMenu li h4 span{width:18px;height:18px;display:block;float:right;}
  .newsMenu li h4 span img{width:18px;height:18px;margin-top:12px;}
  .newsList{width:94%;padding:20px 3%;}
  .newsList h2{width:100%;height:20px;line-height:20px;font-weight:600;font-size:18px;letter-spacing:1px;color: #333;text-align: left;vertical-align:top;}
  .newsList h2 img{width:20px;height:20px;vertical-align: middle;float:left;}
  .newsList .newsUl{width:100%;}
  .newsList .newsUl li{width:100%;height:80px;margin-bottom:20px;overflow:hidden;}
  .newsList .newsUl li img{width:120px;height:80px;float:left;margin-right:10px;}
  .newsList .newsUl li h4{width:calc(100% - 130px);height:48px;line-height:24px;float:left;font-weight:bold;font-size:16px;letter-spacing:1px;color: #333;overflow: hidden;
text-align: left;
font-weight:600;
overflow : hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 2;
}
  .newsList .newsUl li p{width:calc(100% - 130px);height:20px;text-align: left;float:left;font-size:14px;line-height:20px;letter-spacing:1px;color: #999;overflow: hidden;}

  .newsPage{width:100%;text-align: center;margin:100px auto;}
  .newsPage li{width:30px;height:30px;line-height:30px;display:inline-block;text-align: center;border:1px solid #666;font-size:14px;margin:0 10px}
</style>
