<template>
  <div class="payResults">
     <img class="payResultsImg" src="../../assets/img/payResultsImg.png" >
     <h2>支付成功</h2>
     <ul>
      <li>
        <span>下单时间</span><span>2023-04-13 10:00:00</span>
      </li>
      <li>
        <span>下单时间</span><span>2023-04-13 10:00:00</span>
      </li>
     </ul>
  </div>
</template>
 
<script>
import { useRoute, useRouter } from 'vue-router'
import { defineComponent, onMounted, getCurrentInstance ,ref} from "vue";
import axios from 'axios';
import { ElMessage } from 'element-plus'
export default defineComponent({
  setup() {
    const currentInstance = getCurrentInstance();
    const { appContext } = getCurrentInstance();
    const { $axios } = currentInstance.appContext.config.globalProperties;
    const Router = useRouter() //跳转
    const Route = useRoute() //获取到值
    

    let doctorId = Route.query.doctorId;
    let registerHospitalId= Route.query.registerHospitalId;
    let visitDate= Route.query.visitTime;
    const person = ref();
    
    const jiuzhenTime = ref();
    const visitTimeId = ref();
    
    const personList = ref();
    const timeList = ref();
    
    onMounted(()=>{
      $axios.get('api/v1/registerPatient/list',{params: {openid:$cookies.get("openId")}}).then(res => {
        if(res.code == 200){
          personList.value = res.result
        }else{
          console.log('获取就诊人列表')
        }
      })
      $axios.get('api/v1/doctor/getOrderDetail',{params: {doctorId:doctorId,registerHospitalId:'1452811085769940994',visitTime:visitDate}}).then(res => {
        if(res.code == 1){
          timeList.value = res.data
        }else{
          console.log('获取医生出诊信息')
        }
      })
    });
    const selectPerson =(obj) =>{
      person.value = obj.name
      console.log('person',person)
    };
    const selectTime =(obj) =>{
      jiuzhenTime.value = obj
      for(var i=0;i<timeList.value.length;i++){
        if(timeList.value[i].startTime == obj ){
          visitTimeId.value = timeList.value[i].id
        }
      }
      
    };
    const goBack = () => {
      Router.push({
          path:'/Doctor',
          query: {
            doctorId: doctorId
          }
      })
    };
    const onSubmit = () => {
      if(person.value == null){
        ElMessage({
          message: '请选择就诊人',
          type: 'warning',
        })
        return
      }
      else if(jiuzhenTime.value == null){
        ElMessage({
          message: '请选择就诊时间',
          type: 'warning',
        })
       return
      }
      Router.push({
        path:'/AppointmentConfirmation',
        query: {
          doctorId: doctorId,
          visitPerson:person.value,
          visitDate:visitDate,
          visitTime:jiuzhenTime.value,
          visitTimeId:visitTimeId,
          registerHospitalId:registerHospitalId
        }
      })
    };
    
    return {
      goBack,
      timeList,
      selectTime,
      jiuzhenTime,
      person,
      personList,
      selectPerson,
      visitTimeId,
      onSubmit
    }
  }
});
</script>
<style scoped>
  .payResults{width:100%;height:100%}
  .payResultsImg{width:66px;height:66px;margin:80px auto 0}
  .payResults h2{width:100%;height:40px;line-height:40px;font-size:16px;color: #000}
  .payResults ul{width:94%;margin:20px 3%}
  .payResults ul li{width:100%;height:30px;line-height:30px;}
  .payResults ul li span{width:50%;height:30px;display:block;line-height:30px;font-weight:400;font-size: 14px;float: left;}
  .payResults ul li span:first-child{color: #666;text-align:left;}
  .payResults ul li span:last-child {color: #333;text-align:right}
</style>