<template>
  <div class="doctorBox">
    <div class="return">
      <img src="../../assets/img/returnImg.png">返回
    </div>
    <div class="personalBj">
      <img :src="wxInfo1.headimgurl">
      <h4>{{wxInfo1.nickname}}</h4>
    </div>
    <div class="personalNext" @click="goDangan">
      <a>
        <img class="personalImg1" src="../../assets/img/personalImg1.png">
        档案管理
        <img class="personalImg2" src="../../assets/img/personalNext.png">
      </a>
    </div>
  </div>
  <ul class="footTabs">
    <li @click="go('Appointment')"><img src="../../assets/img/footIcon1.png" ><br />首页</li>
    <li @click="go('BookingManagement')"><img src="../../assets/img/footIcon2.png" ><br />预约管理</li>
    <li class="active" @click="go('Personal')"><img src="../../assets/img/footIcon33.png" ><br />我的</li>
  </ul>
</template>
 
<script>
import { useRouter } from 'vue-router'
import { defineComponent,reactive, onMounted, getCurrentInstance ,ref} from "vue";
export default defineComponent({
  setup() {
    const currentInstance = getCurrentInstance();
    const { $axios } = currentInstance.appContext.config.globalProperties;
    const Router = useRouter() //跳转
    const wxInfo = ref({});

    onMounted(()=>{
      //getCode();
      //$cookies.set("openId",'oRBzQs-tc5pXR8oaHMYGEt2qNqYw');
      $axios.get('api/v1/patient/getWxInfo',{params: {openid:$cookies.get("openId")}}).then(res => {
        if (res.code == 1) {
          wxInfo.value = res.data
        } else {
          console.log('res.msg')
        }
      })
    });
    const getCode = async () => {
      var local = window.location.href // 获取页面url
      var code = getUrlCode().code // 截取code
      if (code== null || code == '') { // 如果没有code，则去请求
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4f53ddd052e1798c&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
      } else {
        $axios.get("api/v1/register/getOpenId?code="+code).then(res =>{
          var result1 = res.result
          $cookies.set("openId",result1.openId); 
        })
      }
    };
    const getUrlCode = ()=> { // 截取url中的code方法
        var url = location.search
        var theRequest = new Object()
        if (url.indexOf("?") != -1) {
            var str = url.substr(1)
            var strs = str.split("&")
            for(var i = 0; i < strs.length; i ++) {
                theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
            }
        }
        return theRequest
    };
    const goDangan = () => {
      Router.push({
        path:'/PersonalManagement'
      })
    };
    const go = (data) => {
      Router.push({
          path:'/'+data
      })
    };
    return {
      wxInfo1:wxInfo,
      goDangan,
      go
    }
  }
});
</script>
<style scoped>
  .doctorBox{width:100%;height:100%;background:#f1f1f1;}
  .return{width:96%;height:40px;padding:0 3%;text-align:left;font-size:16px;line-height:40px;letter-spacing:1px;color: #999;background:#fff}
  .return img{width:22px;height:22px;float:left;margin:8px 5px 0 0}
  .personalBj{width:100%;height:150px;background:url(../../assets/img/personBg.png);color:#fff;font-size:18px}
  .personalBj img{width:56px;height:56px;border-radius:50%;overflow:hidden;margin:50px 15px 0 20px;float:left;}
  .personalBj h4{width:calc(100% - 106px);height:22px;line-height:150px;font-weight: 400;float:left;text-align:left;}

  .personalNext{width:100%;display: block;}
  .personalNext a{width:100%;height:44px;display:block;line-height:44px;text-align:left;background:#fff;border-bottom:1px solid rgba(180, 180, 180,0.2);color: #333333}
  .personalImg1{width:24px;height:24px;margin:0 5px 0 20px;vertical-align:sub;}
  .personalImg2{width:18px;height:18px;margin:12px 5px 0 0;vertical-align:sub;float:right;}
</style>