<template>
    <div class="createCon">
      <img src="../../assets/img/createLogo.png">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-width="100px">
        <el-form-item label="患者姓名" prop="name">
          <el-input v-model="ruleForm.name" autocomplete="off"  placeholder="请输入患者姓名" />
        </el-form-item>
        <el-form-item label="证件类型">
          <el-input disabled="true" placeholder="身份证" style="background:none"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="idCard">
           <el-input v-model.trim="ruleForm.idCard" placeholder="请输入证件号"></el-input>
        </el-form-item>
        <el-form-item label="性&nbsp;&nbsp;别" prop="sex"> 
          <el-radio-group v-model.trim="ruleForm.sex">
            <el-radio border label="男" value='1'></el-radio>
            <el-radio border label="女" value='2'></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="联系电话" prop="mobile">
          <el-input v-model.trim="ruleForm.mobile" placeholder="请输入联系电话"></el-input>
        </el-form-item>
         
      </el-form>
      <button class="createBtn"  @click="submitForm(ruleFormRef)">提交</button>
    </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router'
import { reactive, onMounted, onBeforeMount,getCurrentInstance ,ref} from "vue"
import { ElMessage } from 'element-plus'
import { validateVName} from '@/utils/validate'
export default {
    setup() {
      const currentInstance = getCurrentInstance();
      const { $axios } = currentInstance.appContext.config.globalProperties;
      const Router = useRouter() //跳转
      const Route = useRoute() //获取到值
      const ruleFormRef = ref(null)
      
      const btnA = ref(false); 
      const ruleForm = reactive ({
        name: "",
        idCard: "",
        sex:"",
        mobile:''
      });
      onMounted(()=>{
        //getCode();
        //$cookies.set("openId",'odwbRt14B9ztzEhJUFpAfXJ1juAA');
    });
    const getCode = async () => {
      var local = window.location.href // 获取页面url
      var code = getUrlCode().code // 截取code
      if (code== null || code == '') { // 如果没有code，则去请求
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4f53ddd052e1798c&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
      } else {
        $axios.get("api/v1/register/getOpenId?code="+code).then(res =>{
          var result1 = res.result
          $cookies.set("openId",result1.openId); 
        })
      }
    };
    const getUrlCode = ()=> { // 截取url中的code方法
        var url = location.search
        var theRequest = new Object()
        if (url.indexOf("?") != -1) {
            var str = url.substr(1)
            var strs = str.split("&")
            for(var i = 0; i < strs.length; i ++) {
                theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
            }
        }
        return theRequest
    };
      const validateName = (rules,value,callback) => {
        if(value == '') callback(new Error('名字不能为空！'));
        else if(validateVName(value)){
          callback(new Error('不可以带数字！'))
        }else if(value.length >= 10){
          callback(new Error('长度最多10个字符！'))
        }else callback();
      }
      const validateIdCard = (rules,value,callback) => {
        if(value == '') callback(new Error('身份证号不能为空！'));
        else if(value.length != 18){
          callback(new Error('身份证号格式错误'))
        }else callback();
      }
      
      const validateMobile = (rules,value,callback) => {
        if(value == '') callback(new Error('手机号不能为空！'));
        else if(value.length != 11){
          callback(new Error('手机号格式错误'))
        }else callback();
      }
      const rules = {
        name: [{ validator: validateName, trigger: 'blur' }],
        idCard: [{ validator: validateIdCard, trigger: 'change' }],
        sex:[{required: true, message: "请选择性别", trigger: "blur" }],
        mobile:[{ validator: validateMobile, trigger: 'change' },{ pattern:/^1[3|4|5|8|9]{1}[0-9]{9}$/,min: 2, max: 15, message: "手机格式错误", trigger: "blur" },]
      }
      const instance = getCurrentInstance()
      const onInput = () =>{
        instance.proxy.$forceUpdate();
      };
      
      const submitForm = async () => {
        btnA.value = true
        await ruleFormRef.value.validate((valid, fields) => {
          if (valid) {
            if(ruleForm.sex == '男'){
              ruleForm.sex=1
            }else{
              ruleForm.sex=2
            }
            $axios.post("api/v1/registerPatient/registerSave",{
              name:ruleForm.name,
              idCard:ruleForm.idCard,
              sex:ruleForm.sex,
              wxUserinfoId:$cookies.get("openId"),
              mobile:ruleForm.mobile,
              openid:$cookies.get("openId")
            }).then(res =>{
              if(res.code == 200){
                let doctorId= Route.query.doctorId;
                let visitTime= Route.query.visitTime;
                console.log('doctorId',doctorId,doctorId == undefined)
                if(doctorId != undefined){
                  Router.push({
                    path:'/VisitAppointment',
                    query: {
                      doctorId: doctorId,
                      visitDate:visitTime,
                      registerHospitalId:'1452811085769940994'
                    }
                  })
                }else{
                  ElMessage({
                    message: res.message,
                    type: 'success'
                  })
                  Router.push({
                    path:'/Appointment'
                  })
                }
              }else{
                ElMessage({
                  message: '出错了',
                  type: 'warning'
                })
              }
            })
          } else {
            console.log('error submit!', fields)
          }
        })
      }
      return{
        rules,
        ruleForm,
        ruleFormRef,
        onInput,
        btnA,
        submitForm
      }
    }
  }

</script>
<style>
  body,html{background:#f1f1f1;}
  .footTabs{width:100%;position:fixed;left:0;bottom:0;z-index:9999;background: #FFFFFF;margin:0;}
  .footTabs li{width:33.33%;float:left;text-align: center;font-size:14px;line-height:20px;text-align: center;color: #A6AAB3;}
  .footTabs li.active{color: #752526;}
  .footTabs li img{width:26px;height:26px;border-radius:50%;margin-top:5px}
  .createCon{
    width:100%;
    height:100%;
    display: contents;
    position: relative;
  }
  .createCon img{
    margin:30px auto;
  }
  .createCon .el-form .el-form-item__label{
    font-weight:1000;
    font-size:16px;
    color:#333;
    height:40px;
    line-height:40px;
  }
  .createCon .el-form .el-input__wrapper{
    box-shadow:none;
    font-size:16px;
    color:#666;
  }
  .createCon .el-form .el-input.is-disabled .el-input__inner{
    background:none;
  }
  .createCon .el-form .el-input.is-disabled .el-input__wrapper{
    box-shadow:none;
    border:none;
  }
  .createCon .el-form .el-form-item{
    border-bottom:1px solid rgba(180, 180, 180, 0.2);
    margin-bottom:15px
  }
  .createCon .el-form .el-radio.is-bordered{
    border:none
  }
  .createCon .createBtn{
    width: 90%;
    height: 36px;
    border:none;
    background: #752526;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height:36px;
    text-align: center;
    color: #FFFFFF;
    position: absolute;
    left:5%;
    bottom:70px;
  }
</style>