<template>
  <div class="zztImgBox">
		<img class="zztImg" src="../assets/img/zztImg.png" alt="新版挂号平台上线 扫码即可快速预约专家！">
	</div>
</template>
<style>
  *{margin:0;padding:0}
	html,body{background:#fff;overflow: hidden;}
  .zztImgBox{
    padding:20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .zztImg{
    max-width: 100%; 
    height: auto; 
  }
</style>
