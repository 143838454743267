<template>
  <div class="doctorBox">
    <div class="return" @click="goBack">
      <img src="../../assets/img/returnImg.png">返回
    </div>
    <el-form ref="form" :model="form" label-width="80px" style="border-top:1px solid rgba(0, 0, 0, 0.2);padding-top:15px">
      <el-form-item label="医生姓名">
        <el-input disabled=false v-model="doctorDetail1.name"></el-input>
      </el-form-item>
      <el-form-item label="就诊科室">
        <el-input disabled=false v-model="doctorDetail1.departmentStr"></el-input>
      </el-form-item>
      <el-form-item label="就诊人">
        <el-input disabled=false v-model="visitPerson"></el-input>
      </el-form-item>
      <el-form-item label="就诊号种">
        <el-input disabled=false v-model="doctorDetail1.title"></el-input>
      </el-form-item>
      <el-form-item label="费用合计">
        <el-input disabled=false v-model="yuyueguahao"></el-input>
      </el-form-item>
      <el-form-item label="就诊日期">
        <el-input disabled=false  v-model="visitDate"></el-input>
      </el-form-item>
      <el-form-item label="就诊时间">
        <el-input disabled=false  v-model="visitShangwu"></el-input>
      </el-form-item>
      <!-- <el-form-item label="就诊时间">
        <el-input disabled=false  v-model="visitTime"></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="费用合计">
        <el-input disabled=false></el-input>
      </el-form-item> -->
    </el-form>
    <h5 class="visitTishi">【温馨提示】</h5>
    <p class="visitTishi"><br >超过30分钟未支付会导致预约失败。<br >请以收到预约成功短信为准，未收到预约成功信息稍后会自动退款。</p>
    <button class="createBtn" @click="onSubmit" :disabled = btnA>确认并支付</button>
  </div>
</template>
 
<script>
import { useRoute, useRouter } from 'vue-router'
import { defineComponent, onMounted, getCurrentInstance ,ref} from "vue";
import { ElMessage } from 'element-plus'
export default defineComponent({
  setup() {
    const currentInstance = getCurrentInstance();
    const { $axios } = currentInstance.appContext.config.globalProperties;
    const Router = useRouter() //跳转
    const Route = useRoute() //获取到值
    const btnA = ref(false); 
    
    let doctorId = Route.query.doctorId;
    let visitPerson = Route.query.person;
    let visitPersonId = Route.query.personId;
    
    let visitDate = Route.query.visitTime;
    let visitTime = Route.query.jiuzhenTime;
    let visitTimeId = Route.query.visitTimeId;
    let visitTimeDoctorRegisterId = Route.query.visitTimeDoctorRegisterId;
    let registerHospitalId= Route.query.registerHospitalId;
    let visitShangwu = Route.query.visitShangwu;

    const doctorDetail = ref({});
    const yuyueguahao = ref();
    onMounted(()=>{
       $axios.get('api/v1/doctor/shareNew',{params: {doctorId:doctorId,doctorRegisterId:visitTimeDoctorRegisterId}}).then(res => {
        if(res.code == 1){
          const data = res.data
          doctorDetail.value = data;
          yuyueguahao.value = data.hospitalList[0].yuyueguahao
        }else{
            console.log('获取医生出诊信息')
        }
      })
    });
    const goBack = () => {
      Router.push({
          path:'/VisitAppointment',
          query: {
            doctorId: doctorId,
            person:visitPerson,
            visitDate:visitDate,
            registerHospitalId:registerHospitalId
          }
      })
    };
    const onSubmit = () => {
      btnA.value = true
      $axios.post('api/v1/register/surePay',{openid:$cookies.get("openId"),patientId:visitPersonId,isBegin:0,dateTime:visitDate,id:visitTimeId}).then(res => {
        if (res.code === 500) {
          alert(res.message)
        }else {
          $axios.post('api/v1/order/prePay',{doctorId: res.result.doctorId,orderType: res.result.orderType,relationId: res.result.relationId,isShebao:0,openid:$cookies.get("openId")}).then(res => {
            if(res.data == null){
              alert(res.msg);
              setTimeout(() => {
                Router.push({
                  path:'/VisitAppointment',
                  query: {
                    doctorId: doctorId,
                    person:visitPerson,
                    visitDate:visitDate,
                    registerHospitalId:registerHospitalId
                  }
                })
              }, 1000)
              
            } 
            if (res.data.needPay == true) {
              WeixinJSBridge.invoke(
              'getBrandWCPayRequest',{
                appId : res.data.wcPay.appId,
                timeStamp: res.data.wcPay.timeStamp,
                nonceStr: res.data.wcPay.nonceStr,
                package: res.data.wcPay.package,
                signType: res.data.wcPay.signType,
                paySign: res.data.wcPay.paySign
              },function(res){
                console.log('aa',res.err_msg)
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                  
                  alert('购买服务成功，排队出号中。请以收到【挂号成功】手机短信为准。本号源的预约截止时间为提前一天的18:00,如您遇到其他问题需要咨询可以添加客服微信号')
                  ElMessage({
                    message: res.msg,
                    type: 'success'
                  })
                  setTimeout(function(){
                    Router.push({
                      path:'/BookingManagement'
                    })
                  },500);
                }else{
                 // alert('您已取消支付,可在"订单"完成支付')
                }

                // 支付过程中用户取消
                if (res.ZX == "get_brand_wcpay_request:cancel") {
                  alert('您已取消支付,可在"订单"完成支付')
                  this.$router.go(0);
                }
                // 支付失败
                if (res.err_msg == "get_brand_wcpay_request:fail") {
                  alert('支付失败')
                }
                if (res.err_msg == "调用支付JSAPI缺少参数:total_fee") {
                  alert('支付失败')
                  
                }
                Cookies.set('relationId','')
                Cookies.set('doctorRegisterId','')
              }
            )}
          })
        }
      })
    }
    return {
      goBack,
      doctorDetail1:doctorDetail,
      visitDate,
      visitTime,
      visitShangwu,
      visitPerson,
      btnA,
      yuyueguahao,
      onSubmit
    }
  }
});
</script>
<style>
  .doctorBox{width:100%;height:100%}
  .return{width:96%;height:40px;padding:0 3%;text-align:left;font-size:16px;line-height:40px;letter-spacing:1px;color: #999}
  .return img{width:22px;height:22px;float:left;margin:8px 5px 0 0}
  .doctorBox .el-form{
    width: 94%;margin:0 3%;}
  .doctorBox .el-form .el-form-item label{font-weight:1000;font-size:16px;color:#333;height:40px;line-height:40px}
  .doctorBox .el-select,.doctorBox .el-input{width:90%;}
  .doctorBox .el-form .el-input__wrapper{ box-shadow:none;font-size:16px;color:#666}
  .doctorBox .el-form .el-form-item{border-bottom:1px solid rgba(180, 180, 180, 0.2);margin-bottom:15px}
  .doctorBox .el-form .el-input.is-disabled .el-input__wrapper{background:none;border:none;box-shadow:none}
  h5.visitTishi{font-weight: 400;font-size: 16px;line-height:24px;color: #752526;text-align:left;width:94%;margin:0 3%}
  p.visitTishi{font-weight: 500;font-size: 12px;line-height:20px;color: #752526; text-align:left;width:94%;margin:0 3%}
  .createBtn{
    width: 90%;
    height: 36px;
    border:none;
    background: #752526;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height:36px;
    text-align: center;
    color: #FFFFFF;
    position: absolute;
    left:5%;
    bottom:40px;
  }
</style>