import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';

import axios from "@/utils/index"
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'

const app = createApp(App)

app.config.globalProperties.$axios = axios;
app.use(router)
app.use(ElementPlus).mount('#app')
app.provide('$axios', axios)
app.config.globalProperties.$cookies = VueCookies