<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #fff;
}
body,html{
  margin:0!important;
  overflow-x:hidden;
  overflow-y:scroll;
}
h1,h2,h3,h4,h5,h6{margin:0;}
ul{padding:0}
ul li{list-style:none;}
.el-message-box {
  width: 235px;
}
</style>
