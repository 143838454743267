<template>
  <div class="newsDetailBox1">
    <div id="header" class="newsHeader" @click="goBack">
      <img class="newsReturn" src="../../assets/img/newsReturn.png" >
      &nbsp;返回
    </div>
    <div class="newsDetailBox">
      <div class="newsDetail">
        <h2>{{newsDetail.title}}</h2>
        <h6>发布时间：{{newsDetail.createTime}}</h6>
        <video muted="muted" v-if="newsDetail.video != null"  class="video" :src="newsDetail.video" type="video/mp4" poster="false.png" autoplay="autoplay" controls="controls" loop="-1">
          <p>你的浏览器不支持video标签.</p>
        </video>
        <p v-html="newsDetail.content"></p>
      </div>
      <div class="newsDoctor">
        <h2><img src="../../assets/img/newsTitle.png" >&nbsp;推荐名医</h2>
        <div @click="goDoctor(newsDetail.doctorId)">
          <h1>
            <img :src="newsDetail.headImg">
            <!-- <h6>{{newsDetail.doctor}}</h6> -->
          </h1>
          <p>医生：{{newsDetail.doctor}}</p>
          <p>科室：{{newsDetail.department}}</p>
          <p>职称：{{newsDetail.master}}</p>
        </div>
        <p>擅长：{{newsDetail.goodDesc}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router'
import { defineComponent,reactive, onMounted, getCurrentInstance ,ref} from "vue";
import { ElMessage } from 'element-plus'
export default defineComponent({
  setup() {
    const currentInstance = getCurrentInstance();
    const { $axios } = currentInstance.appContext.config.globalProperties;
    const Router = useRouter() //跳转
    const Route = useRoute() //获取到值
    let type = Route.query.type
    let id = Route.query.id;

    const newsDetail = ref({});
    onMounted(()=>{
       $axios.get('api/v1/articleManagement/queryById',{params: {id:id}}).then(res => {
        if(res.code == 200){
          newsDetail.value = res.result;
        }else{
            console.log('获取新闻详细信息')
        }
      })
    });
    const goBack = () => {
      Router.push({
        path:'/newsIndex', query: {
            id: id,
            type:type
          }
        })
    };
    const goDoctor = (doctorId) => {
      Router.push({
        path:'/Doctor', query: {
            doctorId: doctorId
          }
        })
    };
    
    return {
      goBack,
      goDoctor,
      newsDetail,
    }
  }
});
</script>
<style>
  .newsDetailBox1{width:100%;height: 100%;background:#fff;display: table;}
  /* 推荐 */
  .newsHeader{width:94%;height:30px;padding:20px 3% 10px;text-align: left;} 
  .newsHeader .newsReturn{width:17px;height:15px;float:left;margin-top: 3px;}

  .newsDetailBox{width:94%;padding:0 3% 0;}
  .newsDetail{width:100%;overflow-x: hidden;}
  .newsDetail h2{width:100%;line-height:26px;text-align:center;font-weight:bold;font-size:18px;letter-spacing:1px;color: #333;margin-bottom:20px}
  .newsDetail h6{width:100%;height:36px;line-height:36px;text-align:right;font-size:14px;letter-spacing: 1px;color: #333;margin-bottom:20px}
  .newsDetail p {width:100%;text-align:left;text-indent:2em;font-size:16px;line-height:24px;letter-spacing:1px;color: #666;margin-bottom:20px}
  .newsDetail img,.newsDetail p img{width:80% !important}
  .newsDetail .video{width:100%;height:600px;}
  .newsDoctor{width:100%;padding-bottom:100px;}
  .newsDoctor h2{width:100%;height:20px;line-height:20px;font-weight:600;font-size:18px;letter-spacing:1px;color: #333;text-align: left;vertical-align:top;}
  .newsDoctor h2 img{width:20px;height:20px;vertical-align: middle;float:left;}

  .newsDoctor div{width:100%;margin-top:20px}
  .newsDoctor div h1 {width:102px;float:left;margin-right:10px;position: relative;}
  .newsDoctor div h1 img{width:100%;}
  .newsDoctor h1 h6{width:100%;height:30px;line-height:30px;background-image:url("../../assets/img/newsDoctorName.png");background-size:92% 60px;background-repeat:no-repeat;text-align:center;position:absolute;left:8px;top:100px;font-size:14px;color: #FFF}
  .newsDoctor p{width:100%;font-weight: 300;font-size:14px;line-height:24px;color:#333;text-align: left;margin:5px 0;}
</style>
