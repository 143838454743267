<template>
  <div class="bookingManagement" style="margin-bottom:80px">
    <!-- <div class="return">
      <img src="../../assets/img/returnImg.png">返回
    </div> -->
    <!-- <div class="bookingManagePatient">
      <span>就 诊 人</span>
      <img src="../../assets/img/patientImg.png " alt=""> 张三丰
    </div> -->
    
    <ul class="orderMenu">
      <li :class="{ active: isActive == 9 }" @click="handleSelect(isActive=9)">全部<br /><span></span></li>
      <li :class="{ active: isActive == 0 }" @click="handleSelect(isActive=0)">待完成<br /><span></span></li>
      <li :class="{ active: isActive == 2 }" @click="handleSelect(isActive=2)">已完成<br /><span></span></li>
    </ul>
    <h4 class="orderTips">温馨提示：如果就诊日未能就诊，请联系客服退款。</h4>
    <!-- <div class="bookingManageList">
      <img src="../../assets/img/null.png" style="margin-top:100px">
    </div> -->
    <div class="bookingManageList">
      <div class="bookingDiv" v-for="item in bookingList1" :key="item.id" @click="goDetail(item)">
        <span>{{item.registerTime}}</span> 
        <span class="status" v-if="item.status == 2 || item.status == 3" style="background: #E1F5ED">挂号成功</span>
        <span class="status" v-else-if="item.status == 4" style="background: #f5e1e1;color:#f7aaaa">挂号取消</span>
        <span class="status" v-else-if="item.status == 6" >已完成</span>
        <div>
          <div>
            <img :src="item.headImg" />
          </div>
          <h5>{{item.name}} <b>{{item.title}}</b> <u>￥{{item.price}}</u></h5>
          <h6>{{item.departmentName}}</h6>
        </div>
      </div>
    </div>
  </div>
  <ul class="footTabs">
    <li @click="go('Appointment')"><img src="../../assets/img/footIcon1.png" ><br />首页</li>
    <li class="active" @click="go('BookingManagement')"><img src="../../assets/img/footIcon22.png" ><br />预约管理</li>
    <li @click="go('Personal')"><img src="../../assets/img/footIcon3.png" ><br />我的</li>
  </ul>
</template>
 
<script>
import { useRoute, useRouter } from 'vue-router'
import { defineComponent,reactive, onMounted, getCurrentInstance ,ref} from "vue";
export default defineComponent({
  setup() {
    const currentInstance = getCurrentInstance();
    const { $axios } = currentInstance.appContext.config.globalProperties;
    const Router = useRouter() //跳转
    const bookingList = ref({});
    let isActive = ref(9);

    onMounted(()=>{
     // getCode();
      $axios.get('api/v1/register/record',{params: {openid:$cookies.get("openId")}}).then(res => {
        if(res.code == 200){
          bookingList.value = res.result;
          isActive = 9
        }else{
            console.log('获取订单列表')
        }
      })
      //$cookies.set("openId",'oRBzQs-tc5pXR8oaHMYGEt2qNqYw');
    });
    const getCode = async () => {
      var local = window.location.href // 获取页面url
      var code = getUrlCode().code // 截取code
      if (code== null || code == '') { // 如果没有code，则去请求
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4f53ddd052e1798c&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
      } else {
        $axios.get("api/v1/register/getOpenId?code="+code).then(res =>{
          var result1 = res.result
          $cookies.set("openId",result1.openId); 
        })
      }
    };
    const getUrlCode = ()=> { // 截取url中的code方法
        var url = location.search
        var theRequest = new Object()
        if (url.indexOf("?") != -1) {
            var str = url.substr(1)
            var strs = str.split("&")
            for(var i = 0; i < strs.length; i ++) {
                theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
            }
        }
        return theRequest
    };
    const goDetail = (item) => {
      Router.push({
          path:'/BookingDetails',
          query: {
            id: item.id,
            orderPayId:item.orderPayId
          }
      })
    };
    const handleSelect = (num)=>{
      if(num == 9){
        num = ''
      }
      $axios.get('api/v1/register/record',{params: {openid:$cookies.get("openId"),status:num}}).then(res => {
        if(res.code == 200){
          bookingList.value = res.result;
        }else{
            console.log('获取订单列表')
        }
      })
    }
    const go = (data) => {
      console.log('go',data)
      Router.push({
          path:'/'+data
      })
    }
    return {
      bookingList1:bookingList,
      handleSelect,
      isActive,
      goDetail,
      go
    }
  }
});
</script>
<style>
  .bookingManagement{width:100%;height:100%;background: #f1f1f1;}
  .return{width:96%;height:40px;padding:0 3%;text-align:left;font-size:16px;line-height:40px;letter-spacing:1px;color: #999}
  .return img{width:22px;height:22px;float:left;margin:8px 5px 0 0}
  .bookingManagePatient{width:94%;height:44px;padding:0 3%;line-height:44px;color:#fff;background: #752526;font-weight: 500;font-size: 16px;text-align: left;}
  .bookingManagePatient span{margin-right:20px;}
  .bookingManagePatient img{width:16px;height:16px;vertical-align: text-bottom;}
  .orderMenu{width:100%;height:40px;margin:0!important;padding:0 !important}
  .orderMenu li{width:33%;height:40px;line-height:40px;float: left;;margin:0;padding:0 !important;border-bottom: 2px solid transparent;color:#999;}
  .orderMenu li span{width:20px;height:2px;background:#752526;display:block;text-align: center;margin:0 auto;display: none;}
  .orderMenu li.active span{display:block;}

  .orderTips{width:94%;height:40px;line-height:40px;text-align:left;color:#752526;font-size:14px;margin:10px 3%}

  .bookingManageList{width:94%;margin:0 3% 20px}
  .bookingManageList .bookingDiv{width:96%;height:100px;display:block;background:#fff;border-radius:6px;padding:10px 2%;margin-bottom:15px;}
  .bookingManageList .bookingDiv span:first-child{width: 50%;height:17px;display:block;font-weight: 300;font-size: 14px;line-height: 20px;color: #999;text-align: left;float:left;}
  .bookingManageList .bookingDiv span.status{width: 48px;background: #E1F5ED;border-radius:2px;height:17px;text-align: center;display:block;font-weight: 300;font-size: 12px;line-height: 17px;color: #20D76D;float:right;}
  .bookingManageList .bookingDiv div{clear: both;width:100%;height:75px;padding-top:10px;}
  .bookingManageList .bookingDiv div div{width:56px;height:56px;border-radius:100%;overflow: hidden;margin:0 10px;float:left}
  .bookingManageList .bookingDiv div img{width:56px}
  .bookingManageList .bookingDiv div h5{width:calc(100% - 120px);height:30px;float:left;text-align:left;font-size: 16px;line-height:30px;letter-spacing:0.5px;color: #333}
  .bookingManageList .bookingDiv div b{height:30px;font-weight: 300;font-size: 12px;text-align: center;color: #666}
  .bookingManageList .bookingDiv div u{width:30px;height:30px;float:right;font-weight:300;font-size:16px;line-height:30px;color:#333;text-decoration:none;}
  .bookingManageList .bookingDiv div h6{width:calc(100% - 80px);height:30px;float:left;text-align: left;font-size: 12px;line-height:30px;letter-spacing:0.5px;color: #999999;}
</style>